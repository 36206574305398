import { Operator } from "./Operator";

import styles from "./Operator.module.css";

export type OperatorModel = {
    text: string;
    value: string;
};

const EXAMPLES: OperatorModel[] = [
    {
        text: "How many days do I have to get back to the developers once I receive an interconnection request?",
        value: "How many days do I have to get back to the developers once I receive an interconnection request?"
    },
    {
        text: "How do we come to an agreement with developers on who will carry the upgrade cost?",
        value: "How do we come to an agreement with developers on who will carry the upgrade cost?"
    },
    {
        text: "How large can I make my cluster studies?",
        value: "How large can I make my cluster studies?"
    }
];

interface Props {
    onOperatorClicked: (value: string) => void;
}

export const OperatorList = ({ onOperatorClicked }: Props) => {
    return (
        <ul className={styles.energyNavList}>
            {EXAMPLES.map((x, i) => (
                <li key={i}>
                    <Operator text={x.text} value={x.value} onClick={onOperatorClicked} />
                </li>
            ))}
        </ul>
    );
};
