import styles from "./Operator.module.css";

interface Props {
    text: string;
    value: string;
    onClick: (value: string) => void;
}

export const Operator = ({ text, value, onClick }: Props) => {
    return (
        <div className={`${styles.energy} d-inline-block`} onClick={() => onClick(value)}>
            <p className={styles.energyText}>{text}</p>
        </div>
    );
};
