import styles from "./Welcome.module.css";
import communityImage from "../../assets/community.png";

interface Props {
    text: string;
    value: string;
    onClick: (value: string) => void;
}

export const Welcome = ({ text, value, onClick }: Props) => {
    return (
        <div className={styles.welcome} onClick={() => onClick(value)}>
            <p className={styles.welcomeText}>{text}</p>
            {/* <div className="container marketing">
                <div className="row">
                    <div className="col-lg-4">
                        {/* <svg class="bd-placeholder-img rounded-circle" width="140" height="140" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 140x140" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#777"/><text x="50%" y="50%" fill="#777" dy=".3em">140x140</text></svg> -->
                        <img src={communityImage} width="140" height="140"></img>
                        {/* <!-- attribution:Department of Energy https://www.flickr.com/photos/departmentofenergy/44720279095/ -->
                        <h2>Private individual</h2>
                        <p>I'm a community member near a renewable energy project.</p>
                        <p>
                            <a className="btn btn-secondary" href="#">
                                Let's chat &raquo;
                            </a>
                        </p>
                    </div>
                </div> 
                
            </div>*/}
        </div>
    );
};
