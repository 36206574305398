import { Welcome } from "./Welcome";

import styles from "./Welcome.module.css";

export type WelcomeModel = {
    text: string;
    value: string;
};

const WELCOME: WelcomeModel[] = [
    {
        text: "What is the reason for the interconnection reform?",
        value: "What is the reason for the interconnection reform?"
    },
    {
        text: "Why is the interconnection queue backed up so much?",
        value: "Why is the interconnection queue backed up so much?"
    },
    {
        text: "What should I ask my public representatives about that could help?",
        value: "What should I ask my public representatives about that could help?"
    }
];

interface Props {
    onWelcomeClicked: (value: string) => void;
}

export const WelcomeList = ({ onWelcomeClicked }: Props) => {
    return (
        <ul className={styles.welcomeNavList}>
            {WELCOME.map((x, i) => (
                <li key={i}>
                    <Welcome text={x.text} value={x.value} onClick={onWelcomeClicked} />
                </li>
            ))}
        </ul>
    );
};
