import React, { useState } from "react";
import styles from "./PersonaButton.module.css";
import { Persona, OptionValue } from "../../api";
// import { List, Dropdown } from "@fluentui/react";
import { Dropdown } from "react-bootstrap";

interface Props {
    onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    selectedOption: string;
}

const options = [
    { value: "individual", label: Persona.PrivateIndividual },
    { value: "developer", label: Persona.ProjectDeveloper },
    { value: "operator", label: Persona.GridOperator }
];

export const PersonaButton = ({ selectedOption, onChange }: Props) => {
    return (
        <div className={styles.container}>
            {/* <label className={styles.label}>Persona Select :</label> */}
            <select className={"form-select form-select-lg mb-3"} value={selectedOption} onChange={onChange}>
                {options.map(option => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
};
